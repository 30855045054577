import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { Paragraph } from '../';

const StyledMarkdown = styled(ReactMarkdown)`
  text-align: ${({ textAlign }) => textAlign};
`;

const Markdown = ({ textAlign, source, small, large, isOnColor, ...rest }) => {
  const paragraph = ({ children }) => (
    <Paragraph small={small} large={large} isOnColor={isOnColor}>
      {children}
    </Paragraph>
  );
  const renderers = {
    paragraph,
  };
  return (
    <StyledMarkdown
      textAlign={textAlign}
      source={source}
      small={small}
      large={large}
      isOnColor={isOnColor}
      renderers={renderers}
      {...rest}
    />
  );
};

export default Markdown;
