import NextHead from 'next/head';
import { withTheme } from 'styled-components';

const Head = ({ title = 'tretton37 Showcase', theme = {} }) => {
  return (
    <NextHead>
      <meta lang="en" />
      <title>{title}</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="theme-color" content={theme.colors.text.dark.medium} />
    </NextHead>
  );
};

export default withTheme(Head);
