import styled from 'styled-components';
import { forwardRef } from 'react';
import { headingLevels, Heading } from 'shared';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  border: none;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacings.medium};
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.text.dark.medium};
  }
`;

const Input = styled.input`
  background-color: ${({ theme }) => `${theme.colors.background.ternary}`};
  height: 4rem;
  border: ${({ theme }) => `2px solid ${theme.colors.text.dark.medium}`};
  border-radius: 4px;
  line-height: 4rem;
  justify-self: center;
  font-size: 1rem;
  overflow: hidden;
  width: 100%;
  padding: 20px;
  margin-top: 0.5rem;
  font-size: ${({ theme }) => theme.typography.typeScale.large};
  position: relative;
  transition: box-shadow 0.1s ease-out 0s;
  color: ${({ theme }) => theme.colors.text.dark.medium};
  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    font-style: normal;
  }
`;

const TextField = (
  { name = '', placeholder = '', onChange, title, ...rest },
  ref
) => {
  return (
    <Container>
      {title && <Heading headingLevel={headingLevels.h5} text={title} />}
      <Input
        name={name}
        ref={ref}
        onChange={onChange}
        placeholder={placeholder}
        type="text"
        {...rest}
      />
    </Container>
  );
};

export default forwardRef(TextField);
