import {
  regularFont,
  boldFont,
  boldItalicFont,
  headingFont,
  headingLighterFont,
} from './fonts';

const baseFontSize = '16px';

const typeScale = {
  large: '1.5rem',
  medium: '1rem',
  small: '0.75rem',
  button: '1.375rem',
  h1: '4.25rem',
  h2: '3.125rem',
  h3: '2.375rem',
  h4: '1.75rem',
  h5: '1.375rem',
  overline: '1.5rem',
  quote: '1.75rem',
  mobile: {
    large: '1.13rem',
    medium: '1rem',
    small: '0.887rem',
    button: '1.375rem',
    h1: '1.812rem',
    h2: '1.562rem',
    h3: '1.375rem',
    h4: '1.25rem',
    h5: '1.125rem',
    overline: '1rem',
    quote: '1.75rem',
  },
};

export {
  baseFontSize,
  boldFont,
  boldItalicFont,
  headingFont,
  headingLighterFont,
  regularFont,
  typeScale,
};
