import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyles = createGlobalStyle`
    ${normalize}

    html { 
        box-sizing: border-box;
        font-size: ${({ theme }) => theme.typography.baseFontSize};
    }

    body { 
        font-family: ${({ theme }) => theme.typography.regularFont};
        font-size: ${({ theme }) => theme.typography.baseFontSize};
        height: 100%;
        padding:0;
        margin:0;
    }

    #__next {
        height: 100%;
    }

    *, *::after, *::before {
        box-sizing: inherit;
    }

    img {
        width: 100%;
        height: auto;
    }

    ul {
        padding: 0;
        margin: 0;
    }

    strong {
        font-family: ${({ theme }) => theme.typography.boldFont};
    }
`;

export default GlobalStyles;
