import styled from 'styled-components';
import { Picture } from 'shared';

const Size = {
  small: 1,
  large: 2,
};

const Grid = styled.div`
  display: grid;
  grid-auto-rows: 240px;
  grid-gap: ${({ theme }) => theme.spacings.intermediate};
  grid-template-columns: repeat(autofit, minmax(320px, 1fr));

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
    grid-auto-rows: 1fr;
    grid-gap: ${({ theme }) => theme.spacings.xlarge};
    grid-template-columns: repeat(2, minmax(294px, 1fr));
  }
`;

//**********************************************
// Get an arryay of image with every other size.
//**********************************************
const getSortedImages = images => {
  let toggler = false;

  // Sort according to size (large or small).
  const sorted = images.sort(
    (a, b) => Size[b.fields.size] - Size[a.fields.size]
  );

  // Sorting the images so we get every other image large and small.
  const everyOther = sorted
    .map(image => {
      toggler = !toggler;

      return {
        order: toggler ? 1 : 2,
        image,
      };
    })
    .sort((a, b) => a.order - b.order);

  return everyOther.map(imgObj => imgObj.image);
};

const getPictures = images =>
  images.map((image, index) => (
    <Picture
      key={index}
      src={image?.fields?.image?.fields?.file?.url}
      title={image?.fields?.image?.fields?.title}
      size={Size[image?.fields?.size] ?? Size.small}
    />
  ));

const ImageGallery = ({ images }) => {
  const sortedImages = getSortedImages(images);
  const pictures = getPictures(sortedImages);

  return <Grid>{pictures}</Grid>;
};

export default ImageGallery;
