import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Logo, Navigation } from 'components';

const Head = styled.header`
  align-items: center;
  display: flex;
  z-index: 3;
  justify-content: space-between;
  padding: ${({ theme: { spacings } }) =>
    `${spacings.medium} ${spacings.small}`};
  margin: ${({ theme }) => `0 -${theme.spacings.horizontalFlexible}`};
  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
    padding: ${({ theme: { spacings } }) => spacings.intermediate} 0;
    margin: 0;
  }
`;

const loadingVariants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

const Header = () => {
  const router = useRouter();
  return (
    <Head>
      <Navigation />
      <div>
        {router.pathname === '/_error' ? (
          <a href="/">
            <Logo
              variants={loadingVariants}
              initial={'hidden'}
              animate={'visible'}
            />
          </a>
        ) : (
          <Link href="/">
            <Logo
              variants={loadingVariants}
              initial={'hidden'}
              animate={'visible'}
            />
          </Link>
        )}
      </div>
    </Head>
  );
};

export default Header;
