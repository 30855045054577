import styled from 'styled-components';
import { Logo } from 'components';
import { motion } from 'framer-motion';
import Span from '../../shared/Span';

const FooterContainer = styled(motion.footer)`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 2.5rem 0;
  z-index: 0;

  img {
    margin: 0.625rem;

    @media (min-width: 576px) {
      display: none;
    }
  }
`;

const footerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <FooterContainer
      variants={footerVariants}
      initial={'hidden'}
      animate={'visible'}
    >
      <Logo width="6rem" />
      <Span>{`tretton37 © ${year}`}</Span>
    </FooterContainer>
  );
};

export default Footer;
