import styled from 'styled-components';
import { forwardRef } from 'react';

const TextInput = styled.textarea`
  background-color: ${({ theme }) => theme.colors.text.light.high};
  border: ${({ theme }) => `2px solid ${theme.colors.text.dark.medium}`};
  border-radius: 4px;
  line-height: 1.5rem;
  justify-self: center;
  font-size: 1rem;
  overflow: hidden;
  width: 100%;
  padding: 20px;
  font-size: ${({ theme }) => theme.typography.typeScale.medium};
  position: relative;
  transition: box-shadow 0.1s ease-out 0s;
  color: ${({ theme }) => theme.colors.text.dark.medium};

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    font-style: normal;
  }
`;

const TextArea = (
  {
    name = '',
    placeholder = '',
    rows = '',
    defaultValue = null,
    ...inputParams
  },
  ref
) => {
  return (
    <TextInput
      name={name}
      defaultValue={defaultValue}
      rows={rows}
      ref={ref}
      placeholder={placeholder}
      type="text"
      {...inputParams}
    />
  );
};

export default forwardRef(TextArea);
