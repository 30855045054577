import styled from 'styled-components';

import SummaryList from './SummaryList/SummaryList';
import { Heading, Markdown, SectionTitle } from 'shared';

const StyledSection = styled.section`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacings.xlarge};

  @media (min-width: ${({ theme: { breakpoints } = {} }) =>
      breakpoints.laptop}) {
    margin-bottom: ${({ theme }) => theme.spacings.xxlarge};
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-areas:
    'title title'
    'body body'
    'summarylist summarylist';
  grid-template-columns: 1fr 1fr;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-areas:
      'title 1fr'
      'body summarylist';
  }

  .summary-title {
    grid-area: title;
    padding: 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      padding: 0 ${({ theme }) => theme.spacings.xlarge};
    }
  }

  .summary-body {
    grid-area: body;
    padding: 0;
    margin-bottom: 1.5rem;

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      padding: 0 ${({ theme }) => theme.spacings.xlarge};
      margin-bottom: 0;
    }
  }

  .summary-list {
    grid-area: summarylist;
    padding: 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      padding: 0 ${({ theme }) => theme.spacings.xlarge};
    }
  }
`;

const Summary = ({ fields }) => {
  const { sectionTitle, heading, body, list } = fields;

  return (
    <StyledSection>
      <SectionTitle title={sectionTitle} />
      <Container>
        <Heading className="summary-title" text={heading} />
        <Markdown className="summary-body" source={body} large />
        <SummaryList className="summary-list" list={list} />
      </Container>
    </StyledSection>
  );
};

export default Summary;
