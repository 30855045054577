import queryString from 'query-string';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import Page from 'shared/Page';
import { Heading, TextField, headingLevels, Paragraph, Button } from 'shared';
import { Loader } from 'shared/Animations';
import { Modal, SplashScreen } from 'components';

const Container = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacings.xlarge};
  padding: ${({ theme }) => `0 ${theme.spacings.horizontalFlexible}`};
  grid-template-columns: 1fr;
  z-index: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const StartHero = styled(motion.div)`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const StartImage = styled.img`
  background: ${({ url }) => `url(${url}) 0% 0% / cover`};
  background-position-x: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    min-height: 39vh;
  }
`;

const ButtonContainer = styled.div`
  align-self: center;
  button {
    position: relative;
  }
`;

const StyledForm = styled.form`
  display: contents;
  margin-top: ${({ theme }) => theme.spacings.medium};
`;

const TextFieldContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};
  align-self: stretch;
`;

const StyledExplanationParagraph = styled(Paragraph)`
  margin-top: ${({ theme }) => theme.spacings.xsmall};
  align-self: stretch;
`;

const ButtonText = styled.span``;

const ButtonInnerWrapper = styled.span`
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  min-width: 6.56rem;
`;

const Startpage = props => {
  const {
    backgroundImage: { fields: backgroundImage } = {},
    introHeadline,
    welcomingText = 'We need to make sure you’re not sharing our awesome work with others so we’re going to make your experience less tolerable by asking you to log in.',
    buttonText = 'Go',
    placeholder,
    explanationText,
    fieldHeader,
  } = props;

  const router = useRouter();
  const [hasReadQuery, setHasReadQuery] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [route, setRoute] = useState('');

  const submit = e => {
    e.preventDefault();
    setIsLoading(true);
    router.push(route);
  };

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);

    if (parsed.a) {
      setRoute(parsed.a);
    }
    setHasReadQuery(true);
  }, []);

  const handleChange = event => {
    setRoute(event.target.value);
  };

  const loadingVariants = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  };

  return (
    <Page pageKey="startpage">
      <Modal>
        <SplashScreen fields={props.splashScreenFields} />
      </Modal>
      <StartHero
        url={backgroundImage.file.url}
        variants={loadingVariants}
        inital={'hidden'}
        animate={isLoading ? 'hidden' : 'visible'}
      >
        {hasReadQuery && (
          <Container>
            <ContentWrapper>
              <Heading text={introHeadline} headingLevel={headingLevels.h1} />
              <Paragraph large>{welcomingText}</Paragraph>
              <StyledForm onSubmit={submit}>
                <TextFieldContainer>
                  <TextField
                    title={fieldHeader}
                    name="portfolio-slug"
                    placeholder={placeholder}
                    value={route}
                    onChange={handleChange}
                  />
                </TextFieldContainer>
                {explanationText && (
                  <StyledExplanationParagraph small>
                    {explanationText}
                  </StyledExplanationParagraph>
                )}
                <ButtonContainer>
                  <Button
                    secondary
                    disabled={!route}
                    data-cy="start-button"
                    type="submit"
                  >
                    <ButtonInnerWrapper>
                      <ButtonText>{buttonText}</ButtonText>
                      <Loader isLoading={isLoading} />
                    </ButtonInnerWrapper>
                  </Button>
                </ButtonContainer>
              </StyledForm>
            </ContentWrapper>
          </Container>
        )}
        <StartImage
          url={backgroundImage.file.url}
          width="1920px"
          height="1080px"
        />
      </StartHero>
    </Page>
  );
};

export default Startpage;
