import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { browserIsSupported, getBrowser } from 'utils/helpers';
import { Button } from 'shared';

const Container = styled.div`
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  min-height: 4rem;
  background: rgba(255, 255, 255, 0.48);
  text-align: center;
  z-index: 9999;
  border: 1px;
  border: 2px solid #212121;
`;

const Paragraph = styled.p`
  color: red;
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
`;

const Supported = () => {
  const [isSupported, setIsSupported] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (navigator !== undefined) {
      setIsSupported(browserIsSupported(getBrowser()));
    }
  }, []);

  useEffect(() => {
    setIsVisible(!isSupported);
  }, [isSupported]);

  const onClick = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <Container>
        <Paragraph>
          This page is not supported for this browser! Some features may not
          work
        </Paragraph>
        <Button onClick={onClick}>OK</Button>
      </Container>
    )
  );
};

export default Supported;
