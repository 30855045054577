import React, { useMemo } from 'react';
import MotionWrapper from '../MotionWrapper/MotionWrapper';

const Expand = ({
  children,
  duration = 0.8,
  startWidth = 0,
  delay = 0,
  easing = [0.72, 0, 0.28, 1],
  endWidth = '100%',
  className,
  opacityStart = 1,
  opacityEnd = 1,
  startAnimation = true,
}) => {
  const transition = useMemo(
    () => ({
      duration,
      delay,
      ease: easing,
    }),
    [duration, easing]
  );

  const variants = {
    hidden: {
      width: startWidth,
      transition,
      opacity: opacityStart,
    },
    show: {
      width: endWidth,
      transition,
      opacity: opacityEnd,
    },
  };

  return (
    <MotionWrapper
      initial="hidden"
      animate={startAnimation ? 'show' : ''}
      variants={variants}
      className={className}
    >
      {children}
    </MotionWrapper>
  );
};

export default Expand;
