const colors = {
  neutral: {
    0: 'rgb(255, 255, 255)',
    50: 'rgb(242, 242, 242)',
    100: 'rgba(255, 255, 255, 0.88)',
    200: 'rgba(255, 255, 255, 0.64)',
    300: 'rgba(255, 255, 255, 0.48)',
    400: 'rgb(38, 40, 42)', //#26282A
    600: 'rgba(0, 0, 0, 0.32)',
    700: 'rgba(0, 0, 0, 0.48)',
    800: 'rgba(0, 0, 0, 0.64)',
    900: 'rgba(0, 0, 0, 0.88)',
    1000: 'rgba(0, 0, 0, 1)',
  },
  blue: {
    100: 'rgba(0, 30, 60, 0.08)',
    500: '#002B55',
    700: '#001E3C',
  },
  green: {
    500: 'rgb(128, 197, 101)', // #80C565
  },
};

export default colors;
