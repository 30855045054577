import styled from 'styled-components';
import { Expand, Fold } from 'shared/Animations';

const ExpandCover = styled(Expand)`
  position: relative;
  line-height: 0;
`;

const CoverLayer = styled(Fold)`
  position: absolute;
  right: ${({ theme }) => `-${theme.spacings.large}`};
  top: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.background.coverLayer};
  z-index: 100;
  height: 100%;
  margin: ${({ theme }) => `0 ${theme.spacings.large}`};

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    right: 0;
    margin: 0;
  }
`;

const ExpandAndFoldToRight = ({ children, className, startAnimation }) => {
  return (
    <ExpandCover
      className={className}
      duration={0.7}
      delay={0.2}
      startAnimation={startAnimation}
    >
      <CoverLayer
        className={className}
        delay={0.7}
        duration={0.4}
        startAnimation={startAnimation}
      />
      {children}
    </ExpandCover>
  );
};

export default ExpandAndFoldToRight;
