import styled from 'styled-components';
import { Heading, Box, Span } from 'shared';

const List = styled.ul`
  list-style: none;
`;

const SummaryList = ({ list, ...rest }) => {
  return (
    <div {...rest}>
      {list &&
        list.map(ul => (
          <Box key={ul?.sys?.id} direction="column">
            <Heading headingLevel="h5" text={ul.fields.title} />
            <List>
              {ul?.fields?.items.map((item, i) => (
                <li key={`${ul?.sys?.id}-${i}`}>
                  <Span>{item}</Span>
                </li>
              ))}
            </List>
          </Box>
        ))}
    </div>
  );
};

export default SummaryList;
