export const sizes = {
  0: 320,
  1: 375,
  2: 425,
  3: 576,
  4: 768,
  5: 1024,
  6: 1440,
  7: 2560,
};

export default sizes;
