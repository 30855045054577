import styled from 'styled-components';
import { Paragraph } from '../../shared';

const Quote = styled(Paragraph)`
  font-size: ${({ theme }) => theme.typography.typeScale.mobile.h4} !important;
  margin: 0.63rem 0;
  line-height: 1.4;

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.typography.typeScale.h4} !important;
  }
`;

const Subtitle = styled(Paragraph)`
  font-size: ${({ theme }) => theme.typography.typeScale.mobile.medium};
  line-height: 1.5;
  margin: 0.63rem 0 1rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-family: ${({ theme }) => theme.typography.boldFont};
    font-size: ${({ theme }) => theme.typography.typeScale.medium};
  }
`;

const Content = styled.div`
  align-items: ${({ isPositionLeft }) =>
    isPositionLeft ? 'flex-end' : 'flex-start'};
  border-left: ${({ theme, isPositionLeft }) =>
    isPositionLeft ? 0 : `1px solid ${theme.colors.background.border}`};
  border-right: ${({ theme, isPositionLeft }) =>
    isPositionLeft ? `1px solid ${theme.colors.background.border}` : 0};
  display: flex;
  flex-flow: column;
  flex: 1;
  font-family: ${({ theme }) => theme.typography.boldItalicFont};
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacings.xlarge};
  padding-bottom: ${({ theme }) => theme.spacings.xlarge};
  padding-left: ${({ isPositionLeft, theme }) =>
    isPositionLeft ? 0 : theme.spacings.xlarge};
  padding-right: ${({ isPositionLeft, theme }) =>
    isPositionLeft ? theme.spacings.xlarge : 0};

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex: 0 1 50%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: ${({ isPositionLeft }) =>
    isPositionLeft ? 'flex-start' : 'flex-end'};
`;

export const Blockquote = ({ quote, subtitle, isPositionLeft }) => {
  return (
    <Wrapper isPositionLeft={isPositionLeft}>
      <Content isPositionLeft={isPositionLeft}>
        <Quote>&ldquo;{quote}&rdquo;</Quote>
        <Subtitle>{subtitle}</Subtitle>
      </Content>
    </Wrapper>
  );
};
