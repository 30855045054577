import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const placeHolder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=';

const loaded = keyframes`
    0% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
  `;

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;

  &.loaded:not(.has-error) {
    min-width: 0;
    min-height: 0;
    animation: ${loaded} 300ms ease-in-out;
  }
`;

const LazyImage = ({ src, width = '100px', height = '100px', ...rest }) => {
  const [imageSrc, setImageSrc] = useState(placeHolder);
  const [imageRef, setImageRef] = useState(null);
  const [classes, setClasses] = useState({
    loaded: false,
    'has-error': false,
  });

  const onLoad = () => {
    setClasses({
      ...classes,
      loaded: true,
    });
  };

  const onError = () => {
    setClasses({
      ...classes,
      'has-error': true,
    });
  };

  useEffect(() => {
    let observer;
    let didCancel = false;

    if (imageRef && imageSrc === placeHolder) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (
                !didCancel &&
                (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                setImageSrc(src);
              }
            });
          },
          {
            threshold: 0.01,
            rootMargin: '75%',
          }
        );

        observer.observe(imageRef);
      } else {
        setImageSrc();
      }
    }

    return () => {
      didCancel = true;
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef);
      }
    };
  });

  return (
    <StyledImage
      height={height}
      onError={onError}
      onLoad={onLoad}
      ref={setImageRef}
      src={imageSrc}
      width={width}
      {...rest}
    ></StyledImage>
  );
};

export default LazyImage;
