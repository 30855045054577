import React from 'react';
import styled from 'styled-components';
import TitleCard from './TitleCard';
import CoverVideo from './CoverVideo';
import CoverPicture from './CoverPicture';

const CoverWrapper = styled.div`
  margin: ${({ theme }) => `0 -${theme.spacings.horizontalFlexible}`};
  overflow: hidden;
`;

const CoverContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.xlarge};
  position: relative;

  @media (min-width: ${({ theme: { breakpoints } = {} }) =>
      breakpoints.laptop}) {
    margin-bottom: ${({ theme }) => theme.spacings.xxlarge};
  }
`;

const Cover = ({
  className,
  clientName,
  contentType,
  description,
  title,
  src,
}) => {
  const isVideo = contentType.includes('video');
  return (
    <CoverWrapper>
      <CoverContainer className={className}>
        {!isVideo && <CoverPicture src={src} title={title} />}
        {isVideo && (
          <CoverVideo src={src}>
            The video did not load correctly. Or you have a really old browser.
          </CoverVideo>
        )}
        <TitleCard
          title={title}
          clientName={clientName}
          description={description}
        />
      </CoverContainer>
    </CoverWrapper>
  );
};

export default Cover;
