import React from 'react';
import { Span } from '../../shared';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledOverline = styled.div``;

const Overline = ({ children, ...rest }) => {
  return (
    <StyledOverline>
      <Span small isBold isUppercase {...rest}>
        {children}
      </Span>
    </StyledOverline>
  );
};

Overline.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Overline;
