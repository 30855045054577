import sizes from './sizes';

const breakpoints = {
  mobileS: `${sizes[0]}px`,
  mobileM: `${sizes[1]}px`,
  mobileL: `${sizes[2]}px`,
  mobile: `${sizes[3]}px`,
  tablet: `${sizes[4]}px`,
  laptop: `${sizes[5]}px`,
  laptopL: `${sizes[6]}px`,
  desktop: `${sizes[7]}px`,
};

export default breakpoints;
