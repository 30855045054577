import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import ExpandAndFoldToRight from './ExpandAndFoldToRight';
import { useResolution } from 'utils/hooks';

const Container = styled(ExpandAndFoldToRight)`
  height: ${({ height }) => height};
`;

const calculateHeight = (windowWidth, height, width) => {
  return windowWidth ? `${windowWidth / (width / height)}px` : 0;
};

const CoverVideo = ({
  src,
  text,
  autoPlay = true,
  width = '100%',
  height = '100%',
  preload = 'auto',
  muted = true,
  loop = true,
  ...rest
}) => {
  text =
    text ??
    'The video did not load correctly. Or you have a really old browser.';
  const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [videoHeight, setVideoHeight] = useState(null);
  const [mainWidth, setMainWidth] = useState(null);
  const [startAnimation, setStartAnimation] = useState(false);
  const [resolution] = useResolution();

  const onLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    if (videoRef?.current?.readyState === 4) {
      onLoad();
    }

    if (document) {
      setMainWidth(document.getElementsByTagName('main')[0].offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (mainWidth && isLoaded) {
      const { videoHeight, videoWidth } = videoRef.current;
      setVideoHeight(calculateHeight(mainWidth, videoHeight, videoWidth));
    }
  }, [mainWidth, isLoaded]);

  useEffect(() => {
    if (videoHeight !== null) {
      setStartAnimation(true);
    }
  }, [videoHeight]);

  useEffect(() => {
    if (resolution.width && videoHeight !== '100%') {
      setVideoHeight('100%');
    }
  }, [resolution]);

  return (
    <Container height={height} startAnimation={startAnimation}>
      <video
        autoPlay={autoPlay}
        muted={muted}
        preload={preload}
        src={src}
        width={width}
        height={videoHeight}
        ref={videoRef}
        onLoadedData={onLoad}
        loop={loop}
        {...rest}
      >
        {text}
      </video>
    </Container>
  );
};

export default CoverVideo;
