export const browserIsSupported = browser => {
  const browserName = browser.name.toLowerCase();
  let supported = false;

  if (browserName === 'chrome' && browser.version > 56) {
    supported = true;
  } else if (browserName === 'msie' || browser.name === 'ie') {
    supported = false;
  } else if (browserName === 'edge' && browser.version > 15) {
    supported = true;
  } else if (browserName === 'safari' && browser.version > 10) {
    supported = true;
  } else if (browserName === 'firefox' && browser.version > 51) {
    supported = true;
  } else if (browserName === 'samsungbrowser' && browser.version > 5.4) {
    supported = true;
  }

  return supported;
};

export const getBrowser = () => {
  let ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident|samsungbrowser(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }
  if (window.navigator.userAgent.indexOf('Edge') > -1) {
    tem = ua.match(/Edge\/(\d+)/) || ua.match(/Edg\/(\d+)/);
    if (tem != null) {
      return { name: 'Edge', version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: +M[1],
  };
};
