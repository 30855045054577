import styled from 'styled-components';
import { Box, Heading, Markdown } from '../../shared';
import {
  Border,
  StackedHeading,
  IntersectionObserver,
  FadeUp,
} from 'shared/Animations';

const Container = styled.div`
  padding: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => `0 ${theme.spacings.xlarge}`};
  }
`;

const getHeading = (heading, headingLevel, isOnColor, useAnimationBorder) => {
  if (heading) {
    if (useAnimationBorder) {
      return (
        <Border>
          <StackedHeading
            headingLevel={headingLevel}
            isOnColor={isOnColor}
            text={heading}
          />
        </Border>
      );
    }
    return (
      <Heading
        headingLevel={headingLevel}
        isOnColor={isOnColor}
        text={heading}
      />
    );
  }
  return <></>;
};

const getText = (body, isOnColor, useHeadingAnimation) => {
  if (useHeadingAnimation) {
    return (
      <IntersectionObserver>
        <FadeUp initialDelay={0.4} duration={0.8}>
          <Markdown source={body} isOnColor={isOnColor} />
        </FadeUp>
      </IntersectionObserver>
    );
  }
  return <Markdown source={body} isOnColor={isOnColor} />;
};

const Textbox = ({
  body,
  heading,
  headingLevel,
  isOnColor,
  useHeadingAnimation,
  ...rest
}) => {
  return (
    <Container>
      <Box direction="column" {...rest}>
        {getHeading(heading, headingLevel, isOnColor, useHeadingAnimation)}
        {getText(body, isOnColor, useHeadingAnimation)}
      </Box>
    </Container>
  );
};

export default Textbox;
