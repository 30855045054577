import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

const StyledButton = styled(motion.button)`
  border: none;
  padding: 1rem 5.438rem;
  text-transform: uppercase;
  background-color: ${({ theme, secondary }) =>
    secondary ? theme.colors.secondary : theme.colors.primary};
  color: ${({ theme }) => theme.colors.text.light.high};
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.24);
  border-radius: 0.125rem;
  transition: all 0.3s;
  font-weight: 600;
  cursor: pointer;

  :hover {
    background-color: ${({ secondary }) =>
      secondary
        ? '#29425b'
        : '#94CE7E'}; //Not in the theme just blue/green with 16% white overlay
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.24);
  }

  :active {
    background-color: ${({ theme, secondary }) =>
      secondary ? theme.colors.secondary : theme.colors.primary};
    box-shadow: none;
  }

  :disabled {
    background-color: #dddddd;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.text.dark.low};
  }
`;

const Button = ({ secondary, children, ...rest }) => {
  const variants = {
    initial: { opacity: 0, scale: 0.5 },
    animate: { opacity: 1, scale: 1 },
    hover: { opacity: 1, scale: 1.1 },
  };

  return (
    <StyledButton
      secondary={secondary}
      initial="initial"
      animate="animate"
      whileHover="hover"
      variants={variants}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  secondary: PropTypes.bool,
};

Button.defaultProps = {
  secondary: false,
};

export default Button;
