import React from 'react';
import styled from 'styled-components';

import { Textbox } from 'components';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: ${({ theme: { spacings } }) => spacings.intermediate};

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
    grid-column-gap: ${({ theme: { spacings } }) => spacings.xlarge};
  }

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.laptop}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const TextboxSection = props => {
  return (
    <Container>
      <Textbox {...props} />
    </Container>
  );
};

export default TextboxSection;
