import {
  TextAndImage,
  Blockquote,
  SideBySide,
  TextboxSection,
  ImageGallery,
} from 'components';
import { Picture } from 'shared';

const SectionTemplate = ({ fields, templateType, imageHeight }) => {
  switch (templateType) {
    case 'blockqoute':
      return (
        <Blockquote
          isPositionLeft={fields.quotePositionLeft}
          subtitle={fields.subtitle}
          quote={fields.longQuote ?? fields.quote}
        />
      );
    case 'textSection':
      return (
        <TextboxSection
          isSingleSection={true}
          heading={fields.heading}
          body={fields.body}
          sectionTitle={fields.sectionTitle}
        />
      );

    case 'textAndImageSection':
      return (
        <TextAndImage
          body={fields.body}
          heading={fields.heading}
          image={fields.image}
          imageHeight={imageHeight}
          imagePositionLeft={fields.imagePositionLeft}
          isSingleSection={true}
        />
      );
    case 'sideBySide':
      return (
        <SideBySide sectionTitle={fields.sectionTitle} blocks={fields.blocks} />
      );
    case 'imageSection':
      return (
        <Picture
          title={fields.image?.fields?.title}
          src={fields.image?.fields?.file?.url}
        />
      );
    case 'imageGallerySection':
      return (
        <ImageGallery images={fields.images} sectionSize={fields.sectionSize} />
      );

    default:
      return <>No template type found for {templateType}</>;
  }
};

export default SectionTemplate;
