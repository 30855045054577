import { motion } from 'framer-motion';
import styled from 'styled-components';
import { lightTheme } from 'utils/theme/theme';

const LoadingOuterContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
`;
const LoadingContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const LoadingDot = styled(motion.div)`
  height: 0.25rem;
  width: 0.25rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.text.light.high};
  margin: 0 0.15rem;
  display: inline-block;
`;

const Loader = ({ isLoading }) => {
  const loadingOuterContainerVariants = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  };

  // define and set variants for container animation
  const containerAnimation = {
    transition: {
      staggerChildren: 0.4,
    },
  };
  const loadingContainerVariants = {
    start: containerAnimation,
    end: containerAnimation,
  };

  const lightText = lightTheme.colors.text.light.high;
  const leetGreen = lightTheme.colors.background.leetGreen;

  // define keyframes for dot animation
  const backgroundColorsKeyframes = [
    lightText,
    lightText,
    lightText,
    leetGreen,
    lightText,
  ];
  const rotateKeyFrames = [0, 0, 270, 270, 0];
  const scaleKeyFrames = [0.8, 1.2, 0.8, 1.2, 1];
  const borderRadiusKeyFrames = ['20%', '50%', '50%', '20%', '20%'];
  const dotAnimation = {
    scale: scaleKeyFrames,
    rotate: rotateKeyFrames,
    backgroundColor: backgroundColorsKeyframes,
    borderRadius: borderRadiusKeyFrames,
  };

  // set dot animation variants
  const loadingDotsVariants = {
    start: dotAnimation,
    end: dotAnimation,
  };

  // set dot animation transitions
  const loadingDotsTransitions = {
    duration: 1.6,
    yoyo: Infinity,
    ease: 'easeInOut',
  };

  return (
    <LoadingOuterContainer
      isLoading={isLoading}
      variants={loadingOuterContainerVariants}
      animate={isLoading ? 'visible' : 'hidden'}
    >
      <LoadingContainer
        variants={loadingContainerVariants}
        initial="start"
        animate="end"
      >
        {[...Array(5)].map((_, index) => (
          <LoadingDot
            key={index}
            variants={loadingDotsVariants}
            transition={loadingDotsTransitions}
          ></LoadingDot>
        ))}
      </LoadingContainer>
    </LoadingOuterContainer>
  );
};

export default Loader;
