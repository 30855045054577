import styled from 'styled-components';
import { useRouter } from 'next/router';
import Arrow from '../../../public/images/arrow.svg';
import { Span } from 'shared';

const Navlink = styled.button`
  align-items: center;
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  cursor: pointer;
`;

const Img = styled.img`
  height: auto;
  margin-right: 1rem;
  width: 1.25rem;
`;

/*const Paragraph = styled.span`
  font-family: ${({ theme }) => theme.typography.boldFont};
  font-size: ${({ theme }) => theme.typography.typeScale.h5};
`;*/

const Navigation = () => {
  const router = useRouter();
  const isNavVisible = router.query.slug?.length > 1;

  return (
    <div>
      {isNavVisible && (
        <nav>
          <Navlink onClick={() => router.push(`${router.query.slug[0]}`)}>
            <Img
              src={Arrow}
              height="33"
              width="20"
              alt="Back to Portfolio"
            ></Img>
            <Span>To project list</Span>
          </Navlink>
        </nav>
      )}
    </div>
  );
};

export default Navigation;
