import styled from 'styled-components';

import { Picture } from 'shared';
import { Textbox } from 'components';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  grid-column-gap: ${({ theme: { spacings } }) => spacings.intermediate};

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
    grid-column-gap: ${({ theme: { spacings } }) => spacings.xlarge};
  }
  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.mobileS}) {
    grid-template-columns: repeat(auto-fit, minmax(268px, 1fr));
  }
`;

const TextAndImage = ({
  image,
  body,
  heading,
  theme,
  imagePositionLeft = false,
  imageHeight = 472,
  imageWidth = 524,
}) => {
  return (
    <Container imagePositionLeft={imagePositionLeft}>
      {imagePositionLeft && (
        <Picture
          src={image?.fields?.file?.url}
          title={image?.fields?.title}
          theme={theme}
          height={imageHeight}
          width={imageWidth}
        />
      )}
      <Textbox heading={heading} body={body} theme={theme} />
      {!imagePositionLeft && (
        <Picture
          src={image?.fields?.file?.url}
          title={image?.fields?.title}
          theme={theme}
          height={imageHeight}
          width={imageWidth}
        />
      )}
    </Container>
  );
};

export default TextAndImage;
