import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

const headingDefault = css`
  color: ${({ theme, isOnColor }) =>
    isOnColor ? theme.colors.heading.onColor : theme.colors.heading.default};

  letter-spacing: -0.03rem;
  max-width: 20ch;
  font-family: ${({ theme }) => theme.typography.headingFont};
  text-transform: lowercase;
  position: relative;
  hyphens: auto; //TODO this might be a longer discussion if headlines should be allowed to break into 2 lines or not. for now its "fixed"

  :before {
    content: '_';
    position: absolute;
    transform: translateX(-100%);
  }
`;

const H1 = styled(motion.h1)`
  ${headingDefault}
  font-size: ${({ theme }) => theme.typography.typeScale.mobile.h1};
  line-height: 1.1;
  margin-bottom: 0.5rem;
  margin-top: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.typography.typeScale.h1};
    margin-bottom: 2rem;
    margin-top: 4rem;
  }
`;

const H2 = styled(motion.h2)`
  ${headingDefault}
  font-size: ${({ theme }) => theme.typography.typeScale.mobile.h2};
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.typography.typeScale.h2};
    margin-bottom: 1.375rem;
    margin-top: 1.375rem;
  }
`;

const H3 = styled(motion.h3)`
  ${headingDefault}
  font-size: ${({ theme }) => theme.typography.typeScale.mobile.h3};
  line-height: 1.3;
  margin-bottom: 0.375rem;
  margin-top: 0.375rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.typography.typeScale.h3};
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`;

const H4 = styled(motion.h4)`
  ${headingDefault}
  font-size: ${({ theme }) => theme.typography.typeScale.mobile.h4};
  line-height: 1.4;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.typography.typeScale.h4};
    margin-bottom: 0.625rem;
    margin-top: 0.625rem;
  }
`;

const H5 = styled(motion.h5)`
  ${headingDefault}
  font-size: ${({ theme }) => theme.typography.typeScale.mobile.h5};
  line-height: 1.5;
  margin-bottom: 0.125rem;
  margin-top: 0.125rem;
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.typography.typeScale.h5};
    margin-bottom: 0.375rem;
    margin-top: 0.375rem;
  }
`;

export const headingLevels = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
};

export const headingComponents = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
};

const Heading = ({
  isOnColor,
  headingLevel = headingLevels.h2,
  text,
  ...rest
}) => {
  const HeadingElement =
    headingComponents[headingLevel] || headingComponents.h2;
  return text ? (
    <HeadingElement isOnColor={isOnColor} {...rest}>
      {text}
    </HeadingElement>
  ) : null;
};

export default Heading;
