import React from 'react';
import MotionWrapper from '../MotionWrapper/MotionWrapper';

const Expand = ({
  children,
  className,
  opacityStart = 0,
  opacityEnd = 1,
  delay = 0.4,
  duration = 0,
}) => {
  const transition = {
    delay,
    duration,
  };

  const variants = {
    hidden: {
      opacity: opacityStart,
      transition,
    },
    show: {
      transition,
      opacity: opacityEnd,
    },
  };

  return (
    <MotionWrapper
      initial={'hidden'}
      animate={'show'}
      variants={variants}
      className={className}
    >
      {children}
    </MotionWrapper>
  );
};

export default Expand;
