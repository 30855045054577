import { SplashScreenAnimations } from './SplashScreenAnimations';
import { useState, useEffect } from 'react';

const HAS_SEEN_SPLASH_SCREEN_COOKIE = 'hasSeenSplashScreen';

const parseCookies = () => {
  if (global.document) {
    const entries = (global.document?.cookie?.split(';') ?? [])
      .filter(cookie => cookie.length !== 0)
      .map(cookie => cookie.split('='));

    return Object.fromEntries(entries);
  }

  return {};
};

export const SplashScreen = props => {
  const [isSplashScreenVisible, setIsSplashScreenVisible] = useState(false);

  useEffect(() => {
    const cookies = parseCookies();
    if (cookies[HAS_SEEN_SPLASH_SCREEN_COOKIE] !== 'true') {
      setIsSplashScreenVisible(true);
    }
  }, []);

  useEffect(() => {
    if (isSplashScreenVisible) {
      let cookies = parseCookies();
      cookies[HAS_SEEN_SPLASH_SCREEN_COOKIE] = true;
      document.cookie = Object.entries(cookies)
        .map(([name, value]) => `${name}=${value}`)
        .join(';');
    }
  }, [isSplashScreenVisible]);

  return (
    <>
      {isSplashScreenVisible && (
        <SplashScreenAnimations
          onComplete={() => setIsSplashScreenVisible(false)}
          {...props}
        />
      )}
    </>
  );
};
