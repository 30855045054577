import React, { useMemo } from 'react';
import styled from 'styled-components';
import { MotionWrapper } from 'shared/Animations';
import spacings from 'utils/theme/spacings';

const BorderWrapper = styled(MotionWrapper)`
  border-width: ${({ theme }) => theme.spacings.medium};
  border-style: solid;
  padding: 0 ${({ theme }) => theme.spacings.horizontalFlexible};
  border-color: ${({ theme }) => theme.colors.primary};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 -${({ theme }) => theme.spacings.horizontalFlexible} ${({ theme }) => theme.spacings.intermediate};

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
    margin: 0 -${({ theme }) => theme.spacings.intermediate} ${({ theme }) => theme.spacings.intermediate};
    padding: 0 ${({ theme }) => theme.spacings.intermediate};
  }
`;
const BorderCoverTop = styled(MotionWrapper)`
  background-color: ${({ theme }) => theme.colors.background.base};
  position: absolute;
  top: -${({ theme }) => theme.spacings.medium};
  right: 0;
  z-index: 1;
`;
const BorderCoverRight = styled(MotionWrapper)`
  background-color: ${({ theme }) => theme.colors.background.base};
  position: absolute;
  bottom: 0;
  right: -${({ theme }) => theme.spacings.medium};
  z-index: 1;
`;
const BorderCoverBottom = styled(MotionWrapper)`
  background-color: ${({ theme }) => theme.colors.background.base};
  position: absolute;
  bottom: -${({ theme }) => theme.spacings.medium};
  left: 0;
  z-index: 1;
`;
const BorderCoverLeft = styled(MotionWrapper)`
  background-color: ${({ theme }) => theme.colors.background.base};
  position: absolute;
  left: -${({ theme }) => theme.spacings.medium};
  top: 0;
  z-index: 1;
`;

const Border = ({ children, duration = 0.2, className }) => {
  const getTransitions = order => {
    const delayBy = order === 0 ? order : order * duration;
    return useMemo(
      () => ({
        duration,
        delay: delayBy,
      }),
      [duration]
    );
  };

  const getInitalValues = animateVerticalyOrHorizontaly => {
    const values = {
      startWidth: '',
      startHeight: '',
      endWidth: '',
      endHeight: '',
    };
    if (animateVerticalyOrHorizontaly === 'vertical') {
      values.startHeight = `calc(100% + ${spacings.xlarge}*2)`;
      values.startWidth = spacings.large;
      values.endWidth = spacings.large;
      values.endHeight = 0;
    } else {
      values.startHeight = spacings.large;
      values.startWidth = `calc(100% + ${spacings.xlarge})`;
      values.endWidth = 0;
      values.endHeight = spacings.large;
    }
    return values;
  };

  const getVariants = (order, animateVerticalyOrHorizontaly) => {
    const initialValues = getInitalValues(animateVerticalyOrHorizontaly);
    const transition = getTransitions(order);
    return {
      hidden: {
        width: initialValues.startWidth,
        height: initialValues.startHeight,
        transition,
      },
      show: {
        width: initialValues.endWidth,
        height: initialValues.endHeight,
        transition,
      },
    };
  };
  return (
    <BorderWrapper className={className}>
      <BorderCoverTop
        initial="hidden"
        animate={'show'}
        variants={getVariants(2, 'horizontal')}
      ></BorderCoverTop>
      <BorderCoverRight
        initial="hidden"
        animate={'show'}
        variants={getVariants(3, 'vertical')}
      ></BorderCoverRight>
      <BorderCoverBottom
        initial="hidden"
        animate={'show'}
        variants={getVariants(0, 'horizontal')}
      ></BorderCoverBottom>
      <BorderCoverLeft
        initial="hidden"
        animate={'show'}
        variants={getVariants(1, 'vertical')}
      ></BorderCoverLeft>

      {children}
    </BorderWrapper>
  );
};

export default Border;
