import styled from 'styled-components';
import { Thumbnail } from 'components';
import { IntersectionObserver, FadeUp } from 'shared/Animations';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.spacings.large};
  margin-bottom: ${({ theme }) => theme.spacings.xlarge};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: ${({ theme }) => theme.spacings.xlarge};
    margin-bottom: ${({ theme }) => theme.spacings.xxlarge};
  }
`;

const FadeUpAnimation = styled(FadeUp)`
  height: 100%;
`;
const Observer = styled(IntersectionObserver)`
  height: 100%;
`;

const CaseList = ({ cases = [], onClick = () => {}, className }) => {
  const handleClick = (e, slug) => {
    e.preventDefault();
    onClick(slug);
  };

  return (
    <Wrapper>
      {cases
        .filter(a => a.fields)
        .map((c, index) => (
          <Observer key={index} className={className}>
            <FadeUpAnimation
              initialDelay={0.4}
              duration={0.8}
              className={className}
            >
              <Thumbnail
                key={c.fields?.slug + index}
                case={c.fields}
                onClick={e => handleClick(e, c.fields?.slug)}
              />
            </FadeUpAnimation>
          </Observer>
        ))}
    </Wrapper>
  );
};

export default CaseList;
