const spacings = {
  xsmall: '0.25rem',
  small: '0.5rem',
  medium: '1rem',
  large: '2rem',
  intermediate: '2.5rem',
  xlarge: '3.5rem',
  xxlarge: '7rem',
  columnWidth: '32.75rem',
  horizontalFlexible: '8vw',
};

export default spacings;
