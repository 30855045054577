import styled from 'styled-components';

import { SectionTemplate } from 'components';
import { SectionTitle } from 'shared';

const StyledSection = styled.section`
  margin-bottom: ${({ theme }) => theme.spacings.xlarge};
  position: relative;
  @media (min-width: ${({ theme: { breakpoints } = {} }) =>
      breakpoints.laptop}) {
    margin-bottom: ${({ theme }) => theme.spacings.xxlarge};
  }
`;

const Section = ({ children, contentType, fields, title }) => {
  const useSectionTitle = !!title;

  return (
    <StyledSection>
      {useSectionTitle && <SectionTitle title={title} />}
      {children || (
        <SectionTemplate templateType={contentType?.sys?.id} fields={fields} />
      )}
    </StyledSection>
  );
};

export default Section;
