import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import { Cases, Cta, Textbox } from 'components';
import { headingLevels } from '../../shared';
import { IntersectionObserver, FadeUp } from 'shared/Animations';
import Page from 'shared/Page';

const TextboxWrapper = styled.div`
  margin-bottom: 4rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 8rem;
  }
`;

const Portfolio = ({ portfolio = {}, pageKey }) => {
  const router = useRouter();
  const {
    cases = [],
    contactPerson,
    ctaMessage,
    description,
    title,
    slug,
  } = portfolio;

  const onCaseClicked = caseSlug => {
    router.push(`/${slug}/${caseSlug}`);
  };
  return (
    <Page pageKey={pageKey}>
      <TextboxWrapper>
        <Textbox
          headingLevel={headingLevels.h1}
          useHeadingAnimation={true}
          maxWidth="464px"
          heading={title}
          body={description}
        />
      </TextboxWrapper>
      <Cases cases={cases} onClick={onCaseClicked} />
      {contactPerson && ctaMessage && (
        <IntersectionObserver>
          <FadeUp>
            <Cta ctaMessage={ctaMessage} contactPerson={contactPerson} />
          </FadeUp>
        </IntersectionObserver>
      )}
    </Page>
  );
};

export default Portfolio;
