import { motion } from 'framer-motion';
import styled from 'styled-components';
import { headingComponents, headingLevels } from '../../../shared/Heading';

const LetterFrame = styled(motion.span)`
  display: inline-block;
`;

const WordFrame = styled(motion.span)`
  display: inline-block;
  white-space: nowrap;
  margin-right: 0.75rem;
`;

const getWords = text => {
  const words = text.split(' ');
  let currentLetterIndexes = [];

  const getLetterVariants = () => {
    const duration = 0.01;
    // calculate duration based on direction beeing 'backwards'. last to first.
    // last should run before second last and so on.
    // this shouldn't be needed for to only stack them backwards is handled by 'staggerChildren' and 'staggerDirection' below.
    // however only opacity animation would work when only setting variants
    const delay = 1 + duration * (text.length - currentLetterIndexes.length);
    // Variants for animating each letter
    return {
      before: {
        opacity: 0,
        scale: 1.2,
        transition: {
          delay,
          duration,
          type: 'spring',
          damping: 15,
          stiffness: 150,
        },
      },
      after: {
        opacity: 1,
        scale: 1,
        transition: {
          duration,
          delay,
          type: 'spring',
          damping: 15,
          stiffness: 150,
        },
      },
    };
  };

  const getLetters = word => {
    return Array.from(word).map((letter, letterOrder) => {
      currentLetterIndexes.push(letterOrder);
      const letterVariants = getLetterVariants();

      return (
        <LetterFrame
          key={letterOrder}
          initial={'before'}
          animate={'after'}
          variants={letterVariants}
        >
          {letter}
        </LetterFrame>
      );
    });
  };

  // letters has to wrapped in word, or the letters will be wrap
  // also space as letter disappears (collapses) with the animation so we removed those
  // entirely and give each word right margin.
  // option to add unicode non breaking space as a letter was ruled out since it resulted in space at beginning of newlines
  return words.map((word, wordOrder) => {
    return <WordFrame key={wordOrder}>{getLetters(word, wordOrder)}</WordFrame>;
  });
};

const StackedHeading = ({
  isOnColor,
  headingLevel = headingLevels.h2,
  text,
  ...rest
}) => {
  const HeadingElement =
    headingComponents[headingLevel] || headingComponents.h2;
  const animatedHeading = getWords(text);
  // Add staggering effect to the children of the container
  const containerVariants = {
    before: {},
    after: {
      transition: {
        staggerChildren: 0.02,
        staggerDirection: -1, // -1 = last to first. 1 = first to last
      },
    },
  };
  return (
    <HeadingElement
      variants={containerVariants}
      initial="before"
      animate="after"
      isOnColor={isOnColor}
      {...rest}
    >
      {animatedHeading}
    </HeadingElement>
  );
};

export default StackedHeading;
