import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

const Page = ({ children, pageKey }) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log(pageKey);
    }
  }, []);
  return (
    <motion.div
      key={pageKey}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  pageKey: PropTypes.string.isRequired,
};

export default Page;
