import React from 'react';
import styled from 'styled-components';

const BoxContainer = styled.div`
  align-items: ${({ alignItems }) => alignItems};
  display: flex;
  flex-direction: ${({ direction }) => direction};
  flex-grow: 1;
  flex-wrap: wrap;
  flex: ${({ flex }) => flex};
  justify-content: ${({ justifyContent }) => justifyContent};
  margin-left: ${({ theme, spacing }) => `${theme.spacings[spacing]}`};
  margin-top: ${({ theme, spacing }) => `${theme.spacings[spacing]}`};
  padding: ${({ padding }) => padding || ''};
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  max-width: ${({ maxWidth }) => maxWidth};
`;

const Box = ({
  alignItems = 'flex-start',
  children,
  direction = 'row',
  flex,
  maxWidth,
  justifyContent = 'flex-start',
  padding,
  spacing,
  margin,
  width = '100%',
  ...rest
}) => {
  return (
    <BoxContainer
      alignItems={alignItems}
      direction={direction}
      maxWidth={maxWidth}
      justifyContent={justifyContent}
      spacing={spacing}
      width={width}
      margin={margin}
      padding={padding}
      flex={flex}
      {...rest}
    >
      {children}
    </BoxContainer>
  );
};

export default Box;
