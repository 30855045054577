import styled from 'styled-components';
import { FadeIn } from 'shared/Animations';
import { Heading, Overline, Paragraph } from '../../shared';

const Card = styled(FadeIn)`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacings.xlarge};
  padding: ${({ theme }) => `0 ${theme.spacings.horizontalFlexible}`};

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    backdrop-filter: blur(16px);
    background-color: ${({ theme }) => theme.colors.background.ternary};
    bottom: 0;
    left: 0;
    padding: ${({ theme }) => theme.spacings.xlarge};
    position: absolute;
    width: 47%;
    box-shadow: 0 0.5rem 2rem 0 rgba(0, 0, 0, 0.32);
  }
`;

const TitleCard = ({ title, clientName, description }) => {
  return (
    <Card delay={0.6} duration={0.3}>
      <Heading headingLevel="h1" text={title} />
      <Overline>{clientName.fields.name}</Overline>
      <Paragraph>{description}</Paragraph>
    </Card>
  );
};

export default TitleCard;
