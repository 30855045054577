import { Heading, Overline, Paragraph } from 'shared';
import { useResolution } from 'utils/hooks';

const variants = {
  initial: {
    opacity: 0,
    y: 30,
  },
  hover: {
    opacity: 1,
    y: 0,
  },
};

const ThumbnailText = ({ title, clientName, description, isTouchDevice }) => {
  const [, isMobileResolution] = useResolution();
  return (
    <>
      <Heading variants={!isTouchDevice ? variants : null} text={title} />
      <Overline variants={!isTouchDevice ? variants : null}>
        {clientName}
      </Overline>
      {!isMobileResolution && (
        <Paragraph variants={!isTouchDevice ? variants : null}>
          {description}
        </Paragraph>
      )}
    </>
  );
};

export default ThumbnailText;
