import styled from 'styled-components';
import { CaseList } from 'components';

const Wrapper = styled.div([], () => ({
  backgroundColor: 'rgba(255, 255, 255, 1)',
}));

const Cases = ({ cases, onClick }) => {
  return (
    <Wrapper>
      <CaseList cases={cases} onClick={onClick} />
    </Wrapper>
  );
};

export default Cases;
