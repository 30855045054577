import styled from 'styled-components';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

export const StyledSpan = styled(motion.span)`
  line-height: 1.5;
  color: ${({
    theme: {
      colors: { text },
    },
    isOnColor,
    color,
  }) => (color ? color : isOnColor ? text.light.medium : text.dark.medium)};

  font-family: ${({ isBold, theme }) =>
    isBold ? theme.typography.boldFont : theme.typography.regularFont};
  font-size: ${({ theme }) => theme.typography.typeScale.medium};
  ${({ isUppercase }) =>
    isUppercase && {
      textTransform: 'uppercase',
    }}

  font-size: ${({
    theme: {
      typography: {
        typeScale: { mobile },
      },
    },
    small,
    large,
  }) => (small ? mobile.small : large ? mobile.large : mobile.medium)};

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({
      theme: {
        typography: { typeScale },
      },
      small,
      large,
    }) =>
      small ? typeScale.small : large ? typeScale.large : typeScale.medium};
  }
`;

const Span = ({
  children,
  small,
  large,
  isOnColor,
  isUppercase,
  isBold,
  color,
  ...rest
}) => {
  return (
    <StyledSpan
      isOnColor={isOnColor}
      isUppercase={isUppercase}
      isBold={isBold}
      small={small}
      large={large}
      color={color}
      {...rest}
    >
      {children}
    </StyledSpan>
  );
};

Span.propTypes = {
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
  large: PropTypes.bool,
  isOnColor: PropTypes.bool,
  isUppercase: PropTypes.bool,
  isBold: PropTypes.bool,
  color: PropTypes.string,
};

Span.defaultProps = {
  small: false,
  large: false,
  isOnColor: false,
  isUppercase: false,
  isBold: false,
  color: null,
};

export default Span;
