import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import ThumbnailText from './ThumbnailText';
import { Filter, Picture } from 'shared';
import { useDetectTouchDevice } from 'utils/hooks';

const ThumbnailWrapper = styled(motion.div)`
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

const ThumbnailInnerWrapper = styled(motion.div)`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  max-height: ${props => props.height};
  height: ${props => props.height};

  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
    max-height: ${({ mobileHeight, height }) => {
      return mobileHeight
        ? `${mobileHeight}px`
        : height
        ? `${height}px`
        : '160px';
    }};
  }
`;

const Thumbnail = props => {
  const { isTouchDevice } = useDetectTouchDevice(false);
  const { case: caseField, onClick } = props;
  const {
    coverImage: { fields: coverImage = {} },
    title,
    clientName: {
      fields: { name: clientName },
    },
    description,
    thumbnail,
  } = caseField;
  const imageSrc = thumbnail?.fields?.file?.url ?? coverImage?.file?.url;
  const imageTitle = thumbnail?.fields?.title ?? coverImage?.title;
  const containerHeight = 472,
    containerWidth = 629,
    containerMobileHeigth = 208,
    cHeightAsRem = `${containerHeight / 16}rem`,
    cWidthAsRem = `${containerWidth / 16}rem`;
  const variants = {
    initial: {},
    hover: {},
  };

  return (
    <>
      {isTouchDevice !== null && (
        <ThumbnailWrapper
          layout={true}
          width={cWidthAsRem}
          initial={isTouchDevice ? 'hover' : 'initial'}
          whileHover={isTouchDevice ? null : 'hover'}
          variants={variants}
          onClick={onClick}
        >
          <ThumbnailInnerWrapper
            mobileHeight={containerMobileHeigth}
            height={cHeightAsRem}
          >
            <Picture
              src={imageSrc}
              title={imageTitle}
              mobileHeight={containerMobileHeigth}
              height={containerHeight}
              width={containerWidth}
            />
          </ThumbnailInnerWrapper>
          <Filter isTouchDevice={isTouchDevice}>
            <ThumbnailText
              title={title}
              clientName={clientName}
              description={description}
              isTouchDevice={isTouchDevice}
            />
          </Filter>
        </ThumbnailWrapper>
      )}
    </>
  );
};

export default Thumbnail;
