import styled from 'styled-components';

import { LazyImage } from 'components';
import { sizes } from 'utils/theme';
import { motion } from 'framer-motion';

const StyledPicture = styled(motion.picture)`
  width: 100%;
  height: 100%;
  grid-row: ${({ size }) => (size ? `span ${size}` : '')};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-row: ${({ size }) => (size ? `span 1` : '')};
  }
`;

export const aspectRatio = {
  '16/10': { width: 16, height: 10 },
  '21/9': { width: 21, height: 9 },
};

// ****************************************************************
// Calcucalate height based on aspectratio and width
// ****************************************************************
const calculateHeight = (width, aspectRatio) => {
  const widthUnit = width / aspectRatio.width;

  return Math.round(widthUnit * aspectRatio.height);
};

// ****************************************************************
// Get a list of <source /> for given fileformats and resolutions
// ****************************************************************
const getSources = (
  src,
  ratio,
  fileFormats,
  resolutions = [...Object.values(sizes)]
) => {
  return resolutions
    .map((_, index) => {
      return fileFormats.map((format, i) => {
        return (
          <source
            key={src + format + index + i}
            srcSet={`${src}?w=${resolutions[index]}&h=${calculateHeight(
              resolutions[index],
              ratio
            )}&fm=${format} ${resolutions[index]}w`}
            media={`(max-width: ${resolutions[index]}px)`}
            type={`image/${format}`}
          />
        );
      });
    })
    .flat();
};

const Picture = ({ src, title, ratio = aspectRatio['21/9'], size }) => {
  const fileFormats = ['jpg', 'webP'];
  const sources = getSources(src, ratio, fileFormats);

  return (
    <StyledPicture size={size}>
      {sources}
      <LazyImage
        alt={title}
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII="
      />
    </StyledPicture>
  );
};

export default Picture;
