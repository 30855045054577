import styled from 'styled-components';
import { Picture } from 'shared';
import ExpandAndFoldToRight from './ExpandAndFoldToRight';

const Container = styled(ExpandAndFoldToRight)`
  height: 240px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 944px;
  }
`;
const CoverPicture = ({ src, title }) => {
  return (
    <Container>
      <Picture src={src} alt={title}></Picture>
    </Container>
  );
};

export default CoverPicture;
