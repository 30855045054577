import {
  baseFontSize,
  boldFont,
  boldItalicFont,
  headingFont,
  headingLighterFont,
  regularFont,
  typeScale,
} from './typography';
import breakpoints from './breakpoints';
import colors from './colors';
import spacings from './spacings';

const defaultTheme = {
  typography: {
    baseFontSize,
    boldFont,
    boldItalicFont,
    headingFont,
    headingLighterFont,
    regularFont,
    typeScale,
  },
  breakpoints,
  spacings,
};

const light = {
  colors: {
    primary: colors.green[500],
    secondary: colors.blue[500],
    secondaryDark: colors.blue[700],
    background: {
      base: colors.neutral[0],
      body: colors.neutral[0], //Unused
      border: colors.neutral[700],
      buttonPrimary: colors.green[500],
      case: colors.neutral[300],
      coverLayer: colors.neutral[1000],
      disabled: colors.neutral[300],
      leetGreen: colors.green[500],
      primary: colors.neutral[100],
      secondary: colors.blue[500],
      ternary: colors.neutral[300],
    },
    heading: {
      default: colors.blue[500],
      onColor: colors.neutral[100],
    },
    text: {
      dark: {
        high: colors.neutral[900],
        medium: colors.neutral[800],
        low: colors.neutral[700],
      },
      light: {
        high: colors.neutral[100],
        medium: colors.neutral[200],
        low: colors.neutral[300],
      },
    },
  },
};

export const lightTheme = { ...defaultTheme, ...light };
