import { createContext, useRef } from 'react';
import styled from 'styled-components';
import { ThemeProvider, StyleSheetManager } from 'styled-components';

import GlobalFonts from 'utils/theme/fonts';
import { Footer, Header, Head } from 'components';
import { GlobalStyles, theme } from 'utils/theme';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: inherit;
  min-height: 100vh;
  padding: 0 ${({ theme }) => theme.spacings.horizontalFlexible};
  background-color: ${({ theme }) => theme.colors.background.primary};
`;

const Main = styled.main`
  flex: 1;
`;

export const ModalContext = createContext(null);

export const Layout = ({ children }) => {
  const modalNodeRef = useRef(null);

  return (
    <StyleSheetManager
      disableVendorPrefixes={process.env.NODE_ENV === 'development'}
    >
      <ThemeProvider theme={theme}>
        <GlobalFonts />
        <GlobalStyles />
        <Head />
        <Wrapper>
          <div ref={modalNodeRef} />
          <Header />
          <ModalContext.Provider value={modalNodeRef}>
            <Main>{children}</Main>
          </ModalContext.Provider>
          <Footer />
        </Wrapper>
      </ThemeProvider>
    </StyleSheetManager>
  );
};
