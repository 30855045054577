import styled from 'styled-components';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

export const StyledParagraph = styled(motion.p)`
  line-height: 1.5;
  color: ${({
    theme: {
      colors: { text },
    },
    isOnColor,
  }) => (isOnColor ? text.light.medium : text.dark.medium)};

  font-family: ${({ isBold, theme }) =>
    isBold ? theme.typography.boldFont : theme.typography.regularFont};
  font-size: ${({ theme }) => theme.typography.typeScale.medium};
  margin-bottom: 1rem;
  margin-top: 0.375rem;

  ${({ isUppercase }) =>
    isUppercase && {
      textTransform: 'uppercase',
    }}

  max-width: 60ch;

  font-size: ${({
    theme: {
      typography: {
        typeScale: { mobile },
      },
    },
    small,
    large,
  }) => (small ? mobile.small : large ? mobile.large : mobile.medium)};

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({
      theme: {
        typography: { typeScale },
      },
      small,
      large,
    }) =>
      small ? typeScale.small : large ? typeScale.large : typeScale.medium};
  }
`;

const Paragraph = ({
  children,
  small,
  large,
  isOnColor,
  isUppercase,
  isBold,
  ...rest
}) => {
  return (
    <StyledParagraph
      isOnColor={isOnColor}
      isUppercase={isUppercase}
      isBold={isBold}
      small={small}
      large={large}
      {...rest}
    >
      {children}
    </StyledParagraph>
  );
};

Paragraph.propTypes = {
  children: PropTypes.node,
  small: PropTypes.bool,
  large: PropTypes.bool,
  isOnColor: PropTypes.bool,
  isUppercase: PropTypes.bool,
  isBold: PropTypes.bool,
};

Paragraph.defaultProps = {
  small: false,
  large: false,
  isOnColor: false,
  isUppercase: false,
  isBold: false,
};

export default Paragraph;
