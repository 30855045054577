import React from 'react';
import { Summary, Section, Cover, Cta } from 'components';

import { IntersectionObserver, FadeUp } from 'shared/Animations';
import Page from 'shared/Page';

const Case = ({ fields, portfolio = {}, pageKey }) => {
  const {
    clientName,
    coverImage,
    description,
    sections,
    summary,
    title,
  } = fields;
  const { ctaMessage, contactPerson } = portfolio;

  return (
    <Page pageKey={pageKey}>
      <Cover
        clientName={clientName}
        description={description}
        title={title}
        src={coverImage?.fields?.file?.url}
        contentType={coverImage?.fields?.file?.contentType}
      />
      <IntersectionObserver>
        <FadeUp>{summary && <Summary fields={summary?.fields} />}</FadeUp>
      </IntersectionObserver>
      {sections?.map((section, key) => (
        <IntersectionObserver key={key}>
          <FadeUp>
            <Section
              contentType={section?.sys?.contentType}
              fields={section?.fields}
              key={section?.sys?.id}
              title={section?.fields?.sectionTitle}
            ></Section>
          </FadeUp>
        </IntersectionObserver>
      ))}
      <IntersectionObserver>
        <FadeUp>
          {contactPerson && ctaMessage && (
            <Cta ctaMessage={ctaMessage} contactPerson={contactPerson} />
          )}
        </FadeUp>
      </IntersectionObserver>
    </Page>
  );
};

export default Case;
