import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Button, TextField, TextArea, Paragraph } from 'shared';
import { motion } from 'framer-motion';

const StyledForm = styled(motion.form)`
  color: ${({ theme }) => theme.colors.text.light.high};
  width: 80vw;
  max-width: 1000px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => `0 ${theme.spacings.xlarge}`};
  }
  #errorMsg {
    color: ${({ theme }) => theme.colors.text.light.low};
  }
  #errorMsg::before {
    display: inline;
    content: '⚠ ';
  }
`;

const Input = styled(TextField)`
  background-color: ${({ theme }) => theme.colors.text.light.high};
  font-size: ${({ theme }) => theme.typography.typeScale.medium};
  margin: 0 0 ${({ theme }) => theme.spacings.large};
`;

const StyledTextArea = styled(TextArea)`
  margin: 0 0 ${({ theme }) => theme.spacings.medium};
`;

const StyledStatus = styled.div`
  margin: ${({ theme }) => theme.spacings.medium} 0;
`;

const ButtonStyles = styled(Button)`
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const formVariants = {
  hidden: {
    opacity: 0,
    y: 200,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 60,
    },
  },
};

const CtaForm = ({
  contactPerson,
  name = 'email',
  placeholder = 'email@email.com',
  rows = '10',
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });
  const handleResponse = (status, msg) => {
    if (status === 200) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };

  const handleOnSubmit = async (data, e) => {
    e.preventDefault();

    setStatus(prevStatus => ({ ...prevStatus, submitting: true }));

    const res = await fetch('/api/sendemailapi', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailFrom: data.email,
        message: data.message,
        emailTo: contactPerson.email,
      }),
    });

    const text = await res.text();
    handleResponse(res.status, text);
    if (res.status === 200) {
      e.target.reset();
    }
  };

  return (
    <StyledForm
      onSubmit={handleSubmit(handleOnSubmit)}
      variants={formVariants}
      initial="hidden"
      animate="visible"
    >
      <label htmlFor="email-input">
        <Paragraph isOnColor>Email:</Paragraph>
        <Input
          placeholder={placeholder}
          name={name}
          id="email-input"
          ref={register({ required: true })}
          disabled={status.submitted}
        />
      </label>
      {errors.email && <p id="errorMsg">Email is required</p>}
      <label htmlFor="text-area">
        <Paragraph isOnColor>Message:</Paragraph>
        <StyledTextArea
          type="text"
          id="text-area"
          placeholder="Type your message here..."
          rows={rows}
          name="message"
          ref={register({ required: true })}
          disabled={status.submitted}
        />
      </label>
      {errors.message && <p id="errorMsg">A message is required</p>}
      <ButtonStyles type="submit" disabled={status.submitted}>
        {!status.submitting
          ? !status.submitted
            ? 'Send'
            : 'Sent'
          : 'Sending...'}
      </ButtonStyles>
      <StyledStatus>
        {status.info.error && <div>Error: {status.info.msg}</div>}
        {!status.info.error && status.info.msg && <div>{status.info.msg}</div>}
      </StyledStatus>
    </StyledForm>
  );
};

export default CtaForm;
