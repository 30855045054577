import styled from 'styled-components';
import { motion } from 'framer-motion';

const StyledFilter = styled(motion.div)`
  position: ${({ isTouchDevice }) => (isTouchDevice ? 'unset' : 'absolute')};
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${({ theme, isTouchDevice }) =>
    isTouchDevice ? 'transparent' : theme.colors.background.case};
  backdrop-filter: ${({ isTouchDevice }) =>
    isTouchDevice ? 'unset' : 'blur(16px)'};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${({ isTouchDevice }) =>
    isTouchDevice ? 'flex-start' : 'center'};
  padding: 1rem
    ${({ isTouchDevice, theme }) =>
      isTouchDevice ? theme.spacings.medium : theme.spacings.xlarge};
`;

const Filter = ({ children, isTouchDevice }) => {
  const filterVariants = {
    initial: {
      opacity: 0,
    },
    hover: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <StyledFilter variants={filterVariants} isTouchDevice={isTouchDevice}>
      {children}
    </StyledFilter>
  );
};

export default Filter;
