import React, { useMemo, useContext, useEffect, useState } from 'react';

import { IntersectionContext } from '../IntersectionObserver/IntersectionObserver';
import MotionWrapper from '../MotionWrapper/MotionWrapper';

const FadeUp = ({
  children,
  initialDelay = 0,
  duration = 0.6,
  yOffset = 10,
  easing = [0.72, 0, 0.28, 1],
  className,
}) => {
  const { inView } = useContext(IntersectionContext);

  const [delay, setDelay] = useState(initialDelay);

  const transition = useMemo(
    () => ({
      duration,
      delay,
      ease: easing,
    }),
    [duration, easing]
  );

  const variants = {
    hidden: {
      y: yOffset,
      scale: 0.98,
      opacity: 0,
      transition,
    },
    show: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition,
    },
  };

  return (
    <MotionWrapper
      initial="hidden"
      animate={inView ? 'show' : 'hidden'}
      exit="hidden"
      variants={variants}
      className={className}
    >
      {children} {inView}
    </MotionWrapper>
  );
};

export default FadeUp;
