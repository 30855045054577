import React, { useContext } from 'react';
import styled, { withTheme } from 'styled-components';
import { motion } from 'framer-motion';
import { IntersectionContext } from 'shared/Animations/IntersectionObserver/IntersectionObserver';
import { Span } from 'shared';

const StyledSectionTitle = styled(motion.div)`
  margin-bottom: ${({ theme }) => theme.spacings.intermediate};
  width: fit-content;
`;

const StyledText = styled(motion.div)`
  padding: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => `0 ${theme.spacings.xlarge}`};
  }
`;

const Cube = styled(motion.div)`
  background: ${({ theme }) => theme.colors.background.leetGreen};
  content: '';
  height: 1.5rem;
  width: 1.5rem;
  transform: translateX(-100%);
  position: absolute;
  top: 0;
  margin-left: -0.125rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-left: -0.5rem;
  }
`;

const SectionTitle = ({ title, theme = {} }) => {
  const titleText = typeof title === 'string' ? title.toUpperCase() : '';
  const { inView } = useContext(IntersectionContext);

  const containerVariants = {
    initial: {},
    animate: {},
  };

  const cubeVariants = {
    initial: {
      opacity: 0.64,
      scaleX: 0.1,
      x: '-100%',
    },
    animate: {
      opacity: 1,
      scaleX: 1,
      x: '-100%',
      transition: {
        type: 'spring',
      },
    },
  };

  const textVariants = {
    initial: {
      opacity: 0,
      x: 20,
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.8,
        when: 'beforeChildren',
      },
    },
  };

  return (
    <StyledSectionTitle
      initial="initial"
      animate={inView ? 'animate' : 'initial'}
      variants={containerVariants}
    >
      <StyledText variants={textVariants}>
        <Cube variants={cubeVariants} style={{ originX: '100%' }}></Cube>
        <Span isBold isUppercase color={theme.colors.heading.default}>
          {titleText}
        </Span>
      </StyledText>
    </StyledSectionTitle>
  );
};

export default withTheme(SectionTitle);
