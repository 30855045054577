import { useState, useEffect } from 'react';

const useDetectTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(null);

  useEffect(() => {
    const hasDocumentTouchEvent = 'ontouchstart' in document.documentElement;
    setIsTouchDevice(hasDocumentTouchEvent);
  }, []);

  return { isTouchDevice };
};

export default useDetectTouchDevice;
