import React, { forwardRef } from 'react';
import styled from 'styled-components';
import tretton37Logo from '../../../public/images/tretton37_logo.svg';
import { motion } from 'framer-motion';

const Img = styled(motion.img)`
  display: none;
  height: ${({ height }) => `${height}`};
  width: ${({ width }) => `${width}`};
  cursor: pointer;
  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: block;
  }
`;

const Logo = (
  { onClick = undefined, width = '116px', height, ...rest },
  ref
) => {
  return (
    <Img
      width={width}
      onClick={onClick}
      height={height}
      ref={ref}
      src={tretton37Logo}
      alt="tretton37"
      {...rest}
    />
  );
};

export default forwardRef(Logo);
