import { createGlobalStyle } from 'styled-components';

const fallbackFonts = `sans-serif`;
export const regularFont = `Montserrat, ${fallbackFonts}`;
export const boldFont = `MontserratBold, ${fallbackFonts}`;
export const boldItalicFont = `MontserratBoldItalic, ${fallbackFonts}`;
export const headingFont = `MontserratBold, ${fallbackFonts}`;
export const headingLighterFont = `Montserrat, ${fallbackFonts}`;

const GlobalFonts = createGlobalStyle`
    @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Regular.woff2') format('woff2'),
            url('/fonts/Montserrat-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: fallback;
    }
    @font-face {
        font-family: 'MontserratBold';
        src: url('/fonts/Montserrat-Bold.woff2') format('woff2'),
            url('/fonts/Montserrat-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: fallback;
    }
    @font-face {
        font-family: 'MontserratBoldItalic';
        src: url('/fonts/Montserrat-BoldItalic.woff2') format('woff2'),
            url('/fonts/Montserrat-BoldItalic.woff') format('woff');
        font-weight: bold;
        font-style: italic;
        font-display: fallback;
    }
`;

export default GlobalFonts;
