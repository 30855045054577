import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { TextboxSection } from 'components';
import { Button, Box } from 'shared';
import { motion } from 'framer-motion';
import CtaForm from 'components/CtaForm/CtaForm';

const createCta = (ctaMessage = {}, contactPerson) => {
  const text = ctaMessage.text
    ? ctaMessage.text.replace('{name}', `__${contactPerson.name}__`)
    : '';

  return {
    heading: 'Ready to get in touch?',
    text,
    buttonChildren: 'Contact us',
  };
};

const Container = styled.article`
  background: ${({ theme }) => theme.colors.background.secondary};
  display: flex;
  flex-direction: column;
  margin: 3.5rem -${({ theme }) => theme.spacings.horizontalFlexible} 0;
  padding: ${({ theme }) => theme.spacings.horizontalFlexible};
`;

const Cta = ({ ctaMessage, contactPerson }) => {
  const [showForm, setShowForm] = useState(false);
  const [cta, setCta] = useState({});
  const { buttonChildren, heading, text } = cta;

  useEffect(() => {
    setCta(createCta(ctaMessage, contactPerson));
  }, [ctaMessage, contactPerson]);

  function showFormFunction() {
    setShowForm(true);
  }

  return (
    <Container>
      <TextboxSection heading={heading} body={text} isOnColor />
      <Box justifyContent="center" margin={'3.5rem 0 0 0'}>
        <motion.div>
          {!showForm && (
            <Button onClick={showFormFunction}>{buttonChildren}</Button>
          )}
        </motion.div>
      </Box>
      {showForm && <CtaForm contactPerson={contactPerson} />}
    </Container>
  );
};

export default Cta;
