import { useState, useEffect } from 'react';
import sizes from 'utils/theme/sizes';

const useResolution = mobileBreakpoint => {
  const breakpoint = mobileBreakpoint
    ? mobileBreakpoint.replace(/\D/g, '')
    : sizes[3];
  const [resolution, setResolution] = useState({ width: 0, height: 0 });
  const [isMobileResolution, setIsMobileResolution] = useState(false);

  const windowHandler = () => {
    setResolution({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('resize', windowHandler);
      windowHandler();
      return () => window.removeEventListener('resize', windowHandler);
    }
  }, []);

  useEffect(() => {
    const isMobile = resolution.width <= breakpoint;

    if (isMobile !== isMobileResolution) {
      setIsMobileResolution(isMobile);
    }
  }, [resolution]);

  return [resolution, isMobileResolution];
};

export default useResolution;
