import React from 'react';
import MotionWrapper from '../MotionWrapper/MotionWrapper';

const Fold = ({
  children,
  duration = 1.3,
  delay = 1,
  width = '100%',
  easing = [0.72, 0, 0.28, 1],
  className,
  startAnimation = true,
}) => {
  const transition = {
    duration,
    delay,
    ease: easing,
  };

  const variants = {
    hidden: {
      width: 0,
      transition,
    },
    show: {
      width,
      transition,
    },
  };
  return (
    <MotionWrapper
      initial={'show'}
      animate={startAnimation ? 'hidden' : ''}
      variants={variants}
      className={className}
    >
      {children}
    </MotionWrapper>
  );
};

export default Fold;
