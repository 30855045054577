import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';

import colors from 'utils/theme/colors';
import tretton37Logo from '../../../public/images/tretton37_logo.svg';

const Container = styled(motion.div)`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WordContainer = styled(motion.div)`
  position: relative;
  z-index: 2;
`;

const Word = styled(motion.span)`
  font-family: ${({ theme }) => theme.typography.boldFont};
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 5vw;

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 6vw;
  }
`;

const Logo = styled(motion.img)`
  position: absolute;
  z-index: 3;
`;

const Underscore = styled(motion.span)`
  content: '';
  background: black;
  height: 0.5vw;
  opacity: 0;
  width: 3.15vw;
  display: inline-block;
  font-size: 6vw;
  margin-left: 0.6vw;
  background: ${({ theme }) => theme.colors.secondary};
  position: absolute;
  z-index: -1;
  margin: 0;
  bottom: 0.5vw;
`;

const Blob = styled(motion.div)`
  background: ${({ theme }) => theme.colors.secondary};
  position: absolute;
  z-index: 1;
`;

const containerVariants = {
  end: {
    opacity: 0,
    transition: {
      ease: 'easeOut',
      delay: 1,
    },
  },
};

const logoVariants = {
  initial: {
    width: '30vw',
    opacity: 0,
  },
  end: {
    width: '50vw',
    opacity: 1,
    transition: {
      width: { type: 'spring', bounce: 0.5 },
    },
  },
};

const wordContainerVariants = {
  move: {
    top: '8vw',
    transition: {
      top: { type: 'spring', bounce: 0.5 },
    },
  },
};

const blobVariants = {
  scaleUp: {
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    borderRadius: ['0%', '50%', '0%'],
    transition: {
      ease: 'easeOut',
      duration: 0.25,
    },
  },
};

const underscoreVariants = {
  initial: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      repeatDelay: 0.35,
      repeatType: 'loop',
      repeat: Infinity,
      duration: 1,
    },
  },
  roundUp: {
    borderRadius: '25%',
    transition: {
      duration: 0.1,
    },
  },
  stop: {
    opacity: 1,
  },
};

const wordVariants = {
  changeColor: {
    color: `${colors.neutral[0]}`,
  },
};

// eslint-disable-next-line no-undef
const delay = time => new Promise(resolve => setTimeout(resolve, time));

const typeChars = async (words, setWord) => {
  for (const word of words) {
    let currentWord = '';
    for (const char of word.split('')) {
      currentWord += char;
      //Time between char
      await delay(50);
      setWord(currentWord);
    }
    //Time between word
    await delay(300);
  }
  //Time after typing is done
  await delay(250);
};

const writeWords = async (words, setWord) => {
  await typeChars(words, setWord);
};

export const SplashScreenAnimations = ({ onComplete, fields }) => {
  const {
    words: { words },
  } = fields;
  const [word, setWord] = useState('');
  const [doneAnimating, setDoneAnimating] = useState(false);
  const animateContainer = useAnimation();
  const animateLogo = useAnimation();
  const animateWordContainer = useAnimation();
  const animateUnderscore = useAnimation();
  const animateWord = useAnimation();
  const animateBlob = useAnimation();

  useEffect(() => {
    const animate = async () => {
      initialAnimations();
      await writeWords(words, setWord);
      await nextAnimations();
    };
    animate();
  }, []);

  useEffect(() => {
    if (doneAnimating) {
      return onComplete();
    }
  }, [doneAnimating, onComplete]);

  const initialAnimations = async () => {
    await animateUnderscore.start(underscoreVariants.visible);
  };

  const nextAnimations = async () => {
    animateBlob.start(blobVariants.scaleUp);
    animateUnderscore.start(underscoreVariants.stop);
    animateLogo.start(logoVariants.end);
    animateWordContainer.start(wordContainerVariants.move);
    await animateWord.start(wordVariants.changeColor);
    await animateContainer.start(containerVariants.end);

    // Remove splashscreen in parent component
    setDoneAnimating(true);
  };

  return (
    <Container animate={animateContainer}>
      <Logo
        src={tretton37Logo}
        initial={logoVariants.initial}
        animate={animateLogo}
      />
      <WordContainer animate={animateWordContainer}>
        <Word animate={animateWord}>{word}</Word>
        <Underscore
          initial={underscoreVariants.initial}
          animate={animateUnderscore}
        />
      </WordContainer>
      <Blob animate={animateBlob} />
    </Container>
  );
};
