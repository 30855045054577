import { useRouter } from 'next/router';
import styled, { keyframes } from 'styled-components';

import { Button } from 'shared';

const createKeyFrames = numberOfKeyframes => {
  let keyframes = [];

  for (let i = 0; i < numberOfKeyframes; i++) {
    const name = `${Math.floor((i / numberOfKeyframes) * 100)}%`;
    const top = Math.floor(Math.random(100) * 100);
    const bottom = Math.floor(Math.random(101 - top) * 100);
    const keyframe = {
      name,
      top: `${top}`,
      bottom: `${bottom}`,
      clipPath: `inset(${top}% 0 ${bottom}% 0)`,
    };

    keyframes = [...keyframes, keyframe];
  }

  return keyframes;
};

const getKeyframes = (numberOfKeyframes = 30) => {
  const frames = createKeyFrames(numberOfKeyframes);

  const template = frames.reduce((acc, frame) => {
    return (acc += `
      ${frame.name} { 
        top: ${frame.top};
        bottom: ${frame.bottom};
        clip-path: ${frame.clipPath};
      }

    `);
  }, '');

  return template;
};

const glitch = keyframes`
  1% {
    transform: rotateX(10deg) skewX(90deg);
  }
  2% {
    transform: rotateX(0deg) skewX(0deg);
  }
`;

const glitch2 = keyframes`
  1% {
    transform: rotateX(10deg) skewX(70deg);
  }
  2% {
    transform: rotateX(0deg) skewX(0deg);
  }
`;

const noise1 = keyframes`
  ${getKeyframes(30)}
`;

const noise2 = keyframes`
  ${getKeyframes(30)}
`;

const fudge = keyframes`
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(0px, 2%);
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #002b55;
  overflow-y: hidden;
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  flex-flow: column wrap;
  z-index: 2;
`;

const Scanlines = styled.div`
  overflow: hidden;
  mix-blend-mode: difference;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background: repeating-linear-gradient(
      to bottom,
      transparent 0%,
      rgba(255, 255, 255, 0.05) 0.5%,
      transparent 1%
    );

    animation: ${fudge} 4s ease-in-out alternate infinite;
  }
`;

const HeadingWrapper = styled.div`
  position: relative;
  white-space: nowrap;
`;

const Glitch = styled.div`
  color: rgba(128, 197, 101, 1);
  position: relative;
  font-size: 6vw;
  font-weight: 900;
  animation: ${glitch} 4s 4s infinite;

  &::before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: -5px 0 rgba(255, 0, 255, 0.3);
    background: #002b55;
    overflow: hidden;
    top: 0;
    animation: ${noise1} 4s linear infinite alternate-reverse,
      ${glitch} 4s 4.05s infinite;
  }

  &::after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -5px 0 rgba(255, 255, 255, 0.7);
    background: #002b55;
    overflow: hidden;
    top: 0;
    animation: ${noise2} 2s linear infinite alternate-reverse,
      ${glitch} 4s 4s infinite;
  }
`;

const Glow = styled(Glitch)`
  text-shadow: 0 0 1000px rgba(128, 197, 101, 0.7);
  color: transparent;
  position: absolute;
  top: 0;
`;

const Subtitle = styled.p`
  font-weight: 100;
  font-size: 0.8vw;
  color: rgba(128, 197, 101, 0.7);
  text-transform: uppercase;
  letter-spacing: 1em;
  animation: ${glitch2} 4s 4.02s infinite;
`;

const BackButton = styled(Button)`
  margin-top: ${({ theme }) => `${theme.spacings.large}`};
`;

function FourOFour({ buttonText = 'To safety', pageText = 'PAGE NOT FOUND' }) {
  const router = useRouter();

  const handleRouting = event => {
    event.preventDefault();
    router.push('/');
  };

  return (
    <Container>
      <InnerContainer>
        <HeadingWrapper>
          <Glitch data-text={pageText}>{pageText}</Glitch>
          <Glow>{pageText}</Glow>
        </HeadingWrapper>
        <Subtitle>Sorry﹒To﹒Be﹒The﹒Bearer﹒Of﹒Bad﹒News</Subtitle>
        <BackButton className="" onClick={handleRouting}>
          {buttonText}
        </BackButton>
      </InnerContainer>
      <Scanlines />
    </Container>
  );
}
export default FourOFour;
