import React from 'react';
import styled from 'styled-components';

import { SectionTemplate } from 'components';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  grid-gap: ${({ theme: { spacings } }) => spacings.intermediate};

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
    grid-gap: ${({ theme: { spacings } }) => spacings.xlarge};
  }
  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.mobileS}) {
    grid-template-columns: repeat(auto-fit, minmax(268px, 1fr));
  }
`;

const Column = styled.div``;

const SideBySide = ({ blocks }) => {
  return (
    <Container>
      {blocks?.map(({ fields, sys }, i) => (
        <Column key={i}>
          <SectionTemplate
            key={i}
            fields={fields}
            imageHeight={276}
            templateType={sys?.contentType?.sys?.id}
          />
        </Column>
      ))}
    </Container>
  );
};

export default SideBySide;
